import React from "react";

import JarvisLogo from "../../images/Jarvis.png";

import PortfolioPage from "../../components/portfolioPage";

const links = [
	{
		endpoint: "https://github.com/FellowshipOfThePing/Jarvis",
		title: "Github",
	},
	{
		endpoint:
			"https://www.youtube.com/watch?v=CrxmwC2pocQ&feature=youtu.be",
		title: "Youtube",
	},
];

const Jarvis = () => (
	<PortfolioPage
		image={JarvisLogo}
		name="Jarvis"
		description="DIY virtual assistant with the voice of Paul Bettany. Mostly just a fun weekend project"
		links={links}
	/>
);

export default Jarvis;
