import React from "react";
import styled from "styled-components";

import Layout from "./layout";

const imageSize = 70;

const StyledSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	// margin-bottom: 40px;

	.header-section {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}

	.header-text {
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 2rem;
		margin: 0 0 0 20px;
	}
`;

const StyledLink = styled.a`
	margin-bottom: 20px;
	font-size: 1.2rem;
`;

const headerStyles = { margin: "25px 0 15px 0" };

const PortfolioPage = ({ image, name, description, links, children }) => (
	<Layout>
		<StyledSection id="measure">
			<div className="header-section">
				<img
					src={image}
					style={{ borderRadius: "10px" }}
					alt=""
					height={imageSize}
				/>
				<span
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-evenly",
						alignItems: "flex-start",
					}}
				>
					<h2 className="header-text">{name}</h2>
					<p style={{ marginLeft: "20px" }}>{description}</p>
				</span>
			</div>

			<h3 style={headerStyles}>Links</h3>
			{links.map((link, index) => (
				<StyledLink
					href={link.endpoint}
					target="_blank"
					rel="noreferrer"
				>
					{link.title}
				</StyledLink>
			))}

			{children && (
				<>
					<h3 style={headerStyles}>Highlights</h3>
					{children}
				</>
			)}
		</StyledSection>
	</Layout>
);

export default PortfolioPage;
